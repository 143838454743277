module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oleg Gera","short_name":"OG","start_url":"/","background_color":"#343434","theme_color":"#343434","display":"minimal-ui","icon":"src/images/oleggera-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"98d9cba18f42118dfd35e1fb1eeb4efe"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/builds/gerlv/blog/node_modules/gatsby-remark-images","id":"d9be782c-9bb3-5fc8-afc0-78b9fc5e7d6c","name":"gatsby-remark-images","version":"3.8.1","pluginOptions":{"plugins":[],"maxWidth":1360,"withWebp":true,"showCaptions":true,"quality":85,"backgroundColor":"none","linkImagesToOriginal":true,"markdownCaptions":false,"sizeByPixelDensity":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1360,"withWebp":true,"showCaptions":true,"quality":85,"backgroundColor":"none"}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-prismjs","gatsby-remark-copy-linked-files","gatsby-remark-smartypants"],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1360,"withWebp":true,"showCaptions":true,"quality":85,"backgroundColor":"none","linkImagesToOriginal":true,"markdownCaptions":false,"sizeByPixelDensity":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
